<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="会员姓名：">
        <el-input v-model="searchForm.name" size="small" placeholder="请输入会员姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：">
        <el-input v-model="searchForm.mobile" size="small" placeholder="请输入联系电话"></el-input>
      </el-form-item>
      <el-form-item label="申请时间：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="提现单号：">
        <el-input v-model="searchForm.withdraw_no" size="small" placeholder="请输入提现单号"></el-input>
      </el-form-item>
      <el-form-item label="账单状态：">
        <el-select v-model="searchForm.status" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="已驳回" :value="-1"></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="已结算" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="withdraw_no" label="提现单号" align="center"></el-table-column>
      <el-table-column prop="create_time" label="申请时间" align="center">
        <template v-slot="{ row }">{{ row.create_time | getDateformat }}</template>
      </el-table-column>
      <el-table-column prop="name" label="会员姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
      <el-table-column prop="remit_amount" label="提现费用" align="center">
        <template v-slot="{ row }">￥{{ row.remit_amount }}</template>
      </el-table-column>
      <el-table-column prop="withdrawal_amount" label="提现金额" align="center">
        <template v-slot="{ row }">￥{{ row.withdrawal_amount }}</template>
      </el-table-column>
      <el-table-column prop="status" label="账单状态" align="center">
        <template v-slot="{ row }">{{ row.status == -1 ? '已驳回' : row.status == 1 ? '待审核' : '已结算' }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="agree(row)" type="text" size="small" v-if="row.status == 1">通过</el-button>
          <el-button @click="reject(row)" type="text" size="small" v-if="row.status == 1">驳回</el-button>
          <el-button @click="(applyInfo = row), (showRemark = !0)" type="text" size="small" v-if="row.status == -1">驳回原因</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="通过打款" :visible.sync="showAgree" width="650px">
      <el-form label-width="100px">
        <el-form-item label="持卡人姓名：">{{ applyInfo.bank_name }}</el-form-item>
        <el-form-item label="银行卡号：">{{ applyInfo.bank_card }}</el-form-item>
        <el-form-item label="开户行：">{{ applyInfo.bank_info }}</el-form-item>
        <el-form-item label="打款金额：">
          <el-input v-model="auditForm.remit_amount"></el-input>
        </el-form-item>
        <el-form-item label="打款凭证：">
          <ReadyUploadSource @getSource="val => (auditForm.voucher = val.path)" :isManyMax="1" :path="auditForm.voucher" @removeThis="() => (auditForm.voucher = '')"></ReadyUploadSource>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAgree = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAgree">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="驳回" :visible.sync="showReject" width="650px">
      <el-form>
        <el-form-item label="请添加驳回原因：">
          <el-input v-model="auditForm.remark" type="textarea" :rows="5" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReject = !1">取 消</el-button>
        <el-button type="primary" @click="confirmReject">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="驳回原因" :visible.sync="showRemark" width="650px">
      {{ applyInfo.remark }}
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        start_time: '',
        end_time: '',
        withdraw_no: '',
        status: '',
      },
      auditForm: {
        remark: '',
        remit_amount: '',
        voucher: '',
      },
      applyInfo: {},
      list: [],
      total_number: 0,
      showAgree: !1,
      showReject: !1,
      showRemark: !1,
    };
  },
  filters: {
    getDateformat: getDateformat,
  },
  created() {
    // this.searchForm.status = this.$route.query.
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        name: '',
        mobile: '',
        start_time: '',
        end_time: '',
        withdraw_no: '',
        status: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    agree(row) {
      this.applyInfo = row;
      this.showAgree = !0;
      this.auditForm.remit_amount = row.withdrawal_amount;
      this.auditForm.voucher = '';
    },
    reject(row) {
      this.applyInfo = row;
      this.auditForm.remark = '';
      this.showReject = !0;
    },
    confirmAgree() {
      if (!this.auditForm.remit_amount) return this.$message.warning('请填写打款金额');
      if (!this.auditForm.voucher) return this.$message.warning('请添加打款凭证');
      this.$axios
        .post(this.$api.gateway.finance.withdrawalAudit, {
          status: 2,
          id: this.applyInfo.id,
          remit_amount: this.auditForm.remit_amount,
          voucher: this.auditForm.voucher,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('处理成功');
            this.showAgree = !1;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    confirmReject() {
      if (!this.auditForm.remark) return this.$message.warning('请填写驳回原因');
      this.$axios
        .post(this.$api.gateway.finance.withdrawalAudit, {
          status: -1,
          id: this.applyInfo.id,
          remark: this.auditForm.remark,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('处理成功');
            this.showReject = !1;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.start_time = searchForm.start_time[1] / 1000;
        searchForm.end_time = searchForm.start_time[0] / 1000;
      }
      this.$axios.post(this.$api.gateway.finance.withdrawalList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped> 
.el-main {
  background: #fff;
}
</style>

